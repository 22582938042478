import React, { useEffect, useState } from 'react'
import { FieldChange, FormFields } from '../TideForm'
import * as DashboardStyles from '../../../../nationalOnlyPages/women-in-business/dashboard/Dashboard.module.scss'

interface Props {
  handleChange: (field: FieldChange) => void
  formData: FormFields
}

const TideBusinessType = ({ handleChange, formData }: Props) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    formData.businessType?.toString() ?? ''
  )
  const [formLink, setFormLink] = useState<string[]>([])

  useEffect(() => {
    if (formData.businessType) {
      if (formData.businessType === 'new-business') {
        setFormLink([
          '/assets/women-in-business/she-means-business-new-business-form.docx',
          'New Business'
        ])
      } else {
        setFormLink([
          '/assets/women-in-business/she-means-business-existing-business-form.docx',
          'Existing Business'
        ])
      }
    }
  }, [formData.businessType])

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value)
    handleChange({
      name: e.target.name,
      value: e.target.value
    })
  }
  return (
    <div>
      <fieldset>
        <legend>Step 2 - Your Business</legend>
        <p data-radios={true}>
          <label htmlFor={'category'}>
            Please choose one of the below options to start your application
          </label>
          <label
            data-radio={true}
            data-selected={selectedOption === 'new-business'}
          >
            <input
              type="radio"
              name="businessType"
              value="new-business"
              checked={selectedOption === 'new-business'}
              onChange={handleSelectChange}
            />
            New Business
          </label>

          <label
            data-radio={true}
            data-selected={selectedOption === 'existing-business'}
          >
            <input
              type="radio"
              name="businessType"
              value="existing-business"
              checked={selectedOption === 'existing-business'}
              onChange={handleSelectChange}
            />
            Existing Business
          </label>
        </p>
        {formData.businessType && (
          <>
            <p className={DashboardStyles.SmallText}>
              The Application document is provided in an editable <em>.doc</em>{' '}
              format however it must be converted and submitted in <em>.pdf</em>{' '}
              format when you upload.
            </p>
            <p className={DashboardStyles.DownloadButton}>
              <a className={DashboardStyles.Button} href={formLink[0]}>
                Download {formLink[1]} Application
              </a>
            </p>
          </>
        )}
      </fieldset>
    </div>
  )
}

export default TideBusinessType
