import React from 'react'
import DashboardWrapper from '../../../components/BestSchoolsAwards/DashboardWrapper/DashboardWrapperTide'
import * as DashboardStyles from './Dashboard.module.scss'
import { Helmet } from 'react-helmet'
import TideForm from '../../../components/BestSchoolsAwards/Form/TideForm'

const WomenInBusinessEnter = () => {
  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <DashboardWrapper>
        <div className={DashboardStyles.Intro}>
          <TideForm />
        </div>
      </DashboardWrapper>
    </>
  )
}

export default WomenInBusinessEnter
