import React, { ChangeEvent, useState } from 'react'
import { getTideTemporaryLink } from '../../../../api/best-school-awards/dropbox'
import { FormFields } from '../TideForm'

interface FileUploadProps {
  isUploaded: boolean
  fileUploaded: () => void
  formData: FormFields
}

interface ErrorProps {
  state: boolean
  msg: string
}

const TideFileUpload = ({
  isUploaded,
  fileUploaded,
  formData
}: FileUploadProps) => {
  const [uploadLink, setUploadLink] = useState<string | false>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [uploading, setUploading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorProps>({ state: false, msg: '' })
  const [selectedFile, setSelectedFile] = useState<File>()
  const [isFilePicked, setIsFilePicked] = useState<boolean>(false)
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(isUploaded)
  const [readyToUpload, setReadyToUpload] = useState<boolean>(false)

  const isFileValid = (file: File) => {
    if (file.type === 'application/pdf') return true
    return false
  }

  const handleFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const businessName = (formData.business_name as string)
        .replace(/[/\\?%*:|"<>]/g, '-') // Remove invalid characters
        .replace(/[\s]+/g, '_') // Replace spaces with underscores
        .toLowerCase()
      const file = event.target.files[0]
      if (isFileValid(file)) {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
        setLoading(true)
        setError({ state: false, msg: '' })
        setIsFileLoaded(false)
        setUploading(false)
        const fileName = `incomplete/${formData.businessType}/${businessName}_${formData.contact_number}.pdf`
        getTideTemporaryLink({
          fileName
        }).then(result => {
          if (result.success === 0) {
            setLoading(false)
            setError({ state: true, msg: result.message })
          } else {
            if (result.link) {
              setLoading(false)
              setReadyToUpload(true)
              setUploadLink(result.link)
            }
            if (result.error) {
              setError({ state: true, msg: result.error })
            }
          }
        })
      } else {
        setError({
          state: true,
          msg: "This file isn't valid. Please upload PDFs only."
        })
      }
    } else {
      setUploadLink(false)
      setIsFilePicked(false)
    }
  }

  const handleSubmission = () => {
    const maxRetries = 3
    let retries = 0

    const uploadFile = () => {
      if (uploadLink) {
        setUploading(true)
        const headers = new Headers({
          'Content-Type': 'application/octet-stream',
          'User-Agent': 'api-explorer-client'
        })

        fetch(uploadLink, {
          method: 'POST',
          headers: headers,
          body: selectedFile
        })
          .then(response => response.json())
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .then(_result => {
            setIsFileLoaded(true)
            fileUploaded()
            setUploading(false)
          })
          .catch((error: any) => {
            if (retries < maxRetries) {
              retries++
              console.warn(`Retrying upload... Attempt ${retries}`)
              uploadFile()
            } else {
              setError({
                state: true,
                msg: `There was a problem uploading your file after ${maxRetries} attempts. ${error}`
              })
            }
          })
      }
    }

    uploadFile()
  }

  const InputText = () => {
    if (uploading) {
      return <>Loading</>
    }
    if (isFileLoaded) {
      return <>File uploaded</>
    }
    return <>Upload file</>
  }
  return (
    <div>
      {/* <p>
        Please fill out the information required and then convert it to a PDF
        prior to uploading it.
      </p>

      <p className="file-upload">
        <label
          data-file={true}
          data-file-selected={isFilePicked}
          data-loaded={isFileLoaded}
          htmlFor="fileurl"
          className="file-upload__filename"
        >
          {selectedFile?.name
            ? selectedFile.name
            : isFileLoaded
            ? 'Your file has been uploaded. Click again to upload a different file.'
            : 'Choose file'}
        </label>
        <input
          type="file"
          aria-hidden={true}
          hidden={true}
          //   disabled={isFileLoaded}
          id="fileurl"
          onChange={handleFileSelection}
        />
        <button
          type="button"
          onClick={handleSubmission}
          disabled={loading || uploading || !isFilePicked || isFileLoaded}
        >
          <InputText />
        </button>
      </p>
      {selectedFile && isFileLoaded && (
        <small className="file-note">
          <strong>Uploaded</strong>
        </small>
      )}
      {readyToUpload && !isFileLoaded && (
        <small className="file-note">
          <strong>
            Click the Upload button to complete uploading this file.
          </strong>
        </small>
      )}

      {!isFileLoaded && (
        <small className="file-note">
          {' '}
          Only accepts PDFs. Max file size 30mb.
        </small>
      )}
      {error.state && (
        <small
          style={{ color: 'rgb(231, 44, 94)', fontWeight: 'bold' }}
          className="error-msg"
        >
          {error.msg}
        </small>
      )} */}
      <p>
        You can no longer upload or change your PDF as the entry deadline has
        passed.
      </p>
    </div>
  )
}

export default TideFileUpload
