import React, { useContext, useEffect } from 'react'
import { FieldChange, FormFields } from '../TideForm'
import { LoginContext } from '../../../../lib/utils'

interface Props {
  handleChange: (field: FieldChange) => void
  formData: FormFields
}

const fields = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    required: true
  },
  {
    name: 'business_name',
    label: 'Business Name',
    type: 'text',
    required: true
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    required: true
  },
  {
    name: 'contact_number',
    label: 'Contact Number',
    type: 'text',
    required: true
  },
  {
    name: 'website_url',
    label: 'Website URL',
    type: 'text',
    required: false
  },
  {
    name: 'business_address',
    label: 'Business Address (including County)',
    type: 'text',
    required: true
  }
]

const TideBusinessInfo = ({ formData, handleChange }: Props) => {
  const formdata = formData
  const { usr, loading } = useContext(LoginContext)

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange &&
      handleChange({
        name: e.target.name,
        value: e.target.value
      })
  }

  useEffect(() => {
    if (!loading) {
      if (usr?.viewer?.email && !formdata.email) {
        handleChange({
          name: 'email',
          value: usr.viewer.email
        })
      }
    }
  }, [usr, formdata.email, handleChange, loading])
  return (
    <div>
      <fieldset>
        <legend>Step 1 - Registration</legend>
        {fields.map(field => (
          <p key={field.name}>
            <label htmlFor={field.name}>
              {field.label}
              {field.required && <span className="required">*</span>}
              {field.name === 'email' && (
                <span
                  style={{
                    display: 'block',
                    fontWeight: '100',
                    fontSize: '14px',
                    width: '60%',
                    margin: '0 auto'
                  }}
                >
                  Please make sure you enter the same email address as the one
                  you use to open a Tide Business account.
                </span>
              )}
            </label>
            <input
              type={field.type}
              name={field.name}
              id={field.name}
              required={field.required}
              onChange={handleFormChange}
              value={
                (formdata[field.name] as string) ||
                (field.name === 'email' &&
                  !loading &&
                  !formdata.email &&
                  usr?.viewer?.email &&
                  usr.viewer.email) ||
                ''
              }
            />
          </p>
        ))}
      </fieldset>
    </div>
  )
}

export default TideBusinessInfo
