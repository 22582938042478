import React from 'react'
import { FieldChange, FormFields } from '../TideForm'
import TideFileUpload from '../FormHelpers/TideFileUpload'

interface Props {
  handleChange: (field: FieldChange) => void
  formData: FormFields
}

const TideApplicationUpload = ({ handleChange, formData }: Props) => {
  const uploadApplicationSuccess = () => {
    handleChange({
      name: 'have_uploaded_pdf',
      value: true
    })
  }
  return (
    <p>
      <fieldset>
        <legend>Step 3 - Upload Your Application</legend>
        <TideFileUpload
          isUploaded={(formData.have_uploaded_pdf as boolean) ?? false}
          fileUploaded={uploadApplicationSuccess}
          formData={formData}
        />
      </fieldset>
    </p>
  )
}

export default TideApplicationUpload
