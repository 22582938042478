import React, { useEffect, useReducer, useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { LoginContext } from '../../../lib/utils'
import TideBusinessInfo from './FormSteps/TideBusinessInfo'
import TideBusinessType from './FormSteps/TideBusinessType'
import TideApplicationUpload from './FormSteps/TideApplicationUpload'
import getEntries from '../../../api/women-in-business/get-entries'
import {
  SaveWomenInBusiness,
  IWomenInBusinessSave
} from '../../../graphql/queries/SaveWomenInBusiness'
import * as FormStyles from './FormStyles.module.scss'
import TideCTA from './FormSteps/TideCTA'
import { navigate } from 'gatsby'

const stepOneFields = [
  'name',
  'business_name',
  'email',
  'contact_number',
  //   'website_url',
  'business_address'
]

const stepTwoFields = ['businessType']

const stepThreeFields = ['have_uploaded_pdf']

export interface FormFields {
  [key: string]: string | boolean | number
}

export interface FieldChange {
  name: string
  value: string | boolean | number
}

const TideForm = () => {
  const { usr, loading: usrLoading } = useContext(LoginContext)
  const [saveWomenInBusiness, { data, loading, error }] =
    useMutation(SaveWomenInBusiness)
  const [sectionValid, setSectionValid] = useState<boolean>(false)
  const [validSections, setValidSections] = useState<number[]>([])
  const [pressedSave, setPressedSave] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const formReducer = (state: FormFields, event: FieldChange) => {
    return {
      ...state,
      [event.name]: event.value
    }
  }

  const [formData, setFormData] = useReducer(formReducer, {})
  const [formStep, setFormStep] = useState<number>(1)
  const [formElement, setFormElement] = useState<JSX.Element>()

  const decode = (str: string) => {
    const txt = document.createElement('textarea')
    txt.innerHTML = str
    return txt.value
  }

  const checkExistingSubmission = async () => {
    try {
      if (usr?.viewer?.email) {
        await getEntries(usr?.viewer?.email).then(res => {
          const { success, data } = res
          if (!success) return false

          if (typeof data !== 'string') {
            const keys = Object.keys(data)
            keys.forEach((entry: string) => {
              let value = decode(data[entry])
              if (
                entry === 'have_uploaded_pdf' ||
                entry === 'entryId' ||
                entry === 'have_confirmed_tide_account'
              ) {
                value = data[entry]
              }

              setFormData({
                value: value,
                name: entry
              })
            })
          }
          return true
        })
      } else {
        throw new Error('No email found')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (usr?.viewer) {
      checkExistingSubmission()
    } else {
      if (!usrLoading) {
        navigate(
          '/women-in-business/dashboard/login?redirect=/women-in-business/dashboard/enter'
        )
      }
    }
  }, [usr])

  const onSubmit = (formData: IWomenInBusinessSave) => {
    saveWomenInBusiness({
      variables: {
        payload: formData
      }
    })
  }

  const saveForm = () => {
    setPressedSave(true)
    onSubmit(formData)
  }

  useEffect(() => {
    if (!loading && pressedSave) {
      setSaved(true)

      setTimeout(() => {
        setSaved(false)
        setPressedSave(false)
      }, 3000)
    }
  }, [loading])

  useEffect(() => {
    if (!formData.entryId && data?.saveWomenInBusiness?.entryId) {
      setFormData({
        name: 'entryId',
        value: data?.saveWomenInBusiness?.entryId as number
      })
    }
  }, [data])

  const handleChange = (field: FieldChange) => {
    setFormData({
      name: field.name,
      value: field.value
    })
  }

  useEffect(() => {
    if (validSections.includes(formStep)) {
      setSectionValid(true)
    } else {
      setSectionValid(false)
    }
  }, [validSections, formStep])

  useEffect(() => {
    const validSectionsClone = [...validSections]
    if (formStep === 1) {
      //
      if (stepOneFields.every(field => formData[field])) {
        if (!validSectionsClone.includes(1)) {
          validSectionsClone.push(1)
        }
      } else {
        if (validSectionsClone.includes(1)) {
          validSectionsClone.splice(validSectionsClone.indexOf(1), 1)
        }
      }
    } else if (formStep === 2) {
      if (stepTwoFields.every(field => formData[field])) {
        if (!validSectionsClone.includes(2)) {
          validSectionsClone.push(2)
        }
      } else {
        if (validSectionsClone.includes(2)) {
          validSectionsClone.splice(validSectionsClone.indexOf(2), 1)
        }
      }
    } else if (formStep === 3) {
      if (stepThreeFields.every(field => formData[field])) {
        if (!validSectionsClone.includes(3)) {
          validSectionsClone.push(3)
        }
      } else {
        if (validSectionsClone.includes(3)) {
          validSectionsClone.splice(validSectionsClone.indexOf(3), 1)
        }
      }
    }

    setValidSections(validSectionsClone)
  }, [formData, formStep])

  useEffect(() => {
    let formEl: JSX.Element
    // const fd = formData
    switch (formStep) {
      case 1:
        formEl = (
          <TideBusinessInfo formData={formData} handleChange={handleChange} />
        )
        break

      case 2:
        formEl = (
          <TideBusinessType formData={formData} handleChange={handleChange} />
        )
        break

      case 3:
        formEl = (
          <TideApplicationUpload
            formData={formData}
            handleChange={handleChange}
          />
        )
        break

      case 4:
        formEl = <TideCTA />
        break

      default:
        formEl = (
          <TideBusinessInfo formData={formData} handleChange={handleChange} />
        )
        break
    }
    setFormElement(formEl)
  }, [formStep, formData, validSections])

  return (
    <div>
      <>
        <p style={{ textAlign: 'center' }}>
          <strong>
            <em>THIS COMPETITION IS NOW CLOSED TO NEW ENTRIES.</em>
          </strong>
        </p>
        <p>
          For further information or queries, please contact{' '}
          <a href="mailto:hq@muddystilettos.co.uk">hq@muddystilettos.co.uk</a>.
        </p>
        <div className={FormStyles.Form}>{formElement}</div>
        <div className={FormStyles.Buttons}>
          {formStep > 1 && formStep < 4 && (
            <div style={{ gridColumn: '1', textAlign: 'left' }}>
              <button onClick={() => setFormStep(formStep - 1)}>Go Back</button>
            </div>
          )}
          <div
            className={FormStyles.ButtonTexts}
            style={{ gridColumn: '2', textAlign: 'center' }}
          >
            {error && (
              <div className={FormStyles.SaveNotes}>
                <p>There was an error saving your form</p>
              </div>
            )}
            {saved && (
              <div className={FormStyles.SaveNotes}>
                <p>Form saved</p>
              </div>
            )}
          </div>
          {sectionValid && (
            <>
              {/* <div style={{ gridColumn: '2', textAlign: 'center' }}>
                  <button disabled={loading} onClick={() => saveForm()}>
                    Save Form
                  </button>
                </div> */}
              <div style={{ gridColumn: '3', textAlign: 'right' }}>
                <button
                  onClick={() => {
                    setFormStep(formStep + 1)
                    saveForm()
                  }}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </>
    </div>
  )
}

export default TideForm
