// extracted by mini-css-extract-plugin
export var AdditionalInformationNote = "FormStyles-module--AdditionalInformationNote--7e87f";
export var ApplicationFormPointer = "FormStyles-module--ApplicationFormPointer--55928";
export var Button = "FormStyles-module--Button--0228c";
export var ButtonTexts = "FormStyles-module--ButtonTexts--01b17";
export var Buttons = "FormStyles-module--Buttons--7df84";
export var DownloadButton = "FormStyles-module--DownloadButton--de023";
export var Error = "FormStyles-module--Error--67549";
export var ErrorMsg = "FormStyles-module--ErrorMsg--277fe";
export var FileNote = "FormStyles-module--FileNote--b5a67";
export var Form = "FormStyles-module--Form--eaa5d";
export var IsSuggestion = "FormStyles-module--IsSuggestion--35388";
export var NoSuggestions = "FormStyles-module--NoSuggestions--3c764";
export var SchoolSuggestions = "FormStyles-module--SchoolSuggestions--0e95f";
export var SubmitCTA = "FormStyles-module--SubmitCTA--dd50c";
export var SuggestionsWrapper = "FormStyles-module--SuggestionsWrapper--91c21";
export var Upload = "FormStyles-module--Upload--2cbc0";
export var UploadCTA = "FormStyles-module--UploadCTA--065bd";
export var UploadFileField = "FormStyles-module--UploadFileField--1300a";