import React from 'react'
import * as DashboardStyles from '../../../../nationalOnlyPages/women-in-business/dashboard/Dashboard.module.scss'

const TideCTA = () => {
  return (
    <div>
      <fieldset>
        <legend>Step 4 - Sign up for Tide</legend>
        <div className={DashboardStyles.CenterAligned}>
          <h3>WIN £10,000!</h3>
          <p>You’re almost there! </p>
          <p>
            <a
              href="https://www.tide.co/offers/muddystilettos-shemeansbusiness/"
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>
            &nbsp;to head over to Tide and create your free business account -
            it&apos;s easy and takes minutes (don&apos;t forget to sign up using
            the same email address as you have registered with Muddy Stilettos)
          </p>
          <p>
            Then check your inbox (including junk!) for a SHE MEANS BUSINESS
            email from Tide that will direct you back to Muddy Stilettos to
            confirm your entry. This can take up to 12hrs to arrive while Tide
            processes your details.
          </p>
          <a
            href="https://www.tide.co/offers/muddystilettos-shemeansbusiness/"
            className={DashboardStyles.Button}
            target="_blank"
            rel="noreferrer"
          >
            OPEN YOUR FREE ACCOUNT
          </a>
        </div>
      </fieldset>
    </div>
  )
}

export default TideCTA
